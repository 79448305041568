<template>
    <div class="card mx-auto mb-3">
        <div class="card-body">
            <div class="mb-3">
                <div class="">
                    <h4 class="text-nowrap">
                        {{ $t('support-title') }}
                    </h4>
                </div>
            </div>
            <div class="chat-wrapper mx-2 p-0">

                <div v-for="message in messages.messages" :key="message.id" class="d-flex text-wrap">
                    <div class="chat-box-wrapper chat-box-wrapper-right" v-if="message.from === userId">
                        <div>
                            <div class="chat-box-right">
                                {{message.message}}
                            </div>
                            <small class="opacity-6">
                                <font-awesome-icon icon="calendar-alt" />{{new Date(parseInt(message.id))|formatDate}}
                            </small>
                        </div>
                    </div>

                    <div class="chat-box-wrapper" v-else>
                        <div>
                            <div class="chat-box">
                                {{message.message}}
                            </div>
                            <small class="opacity-6">
                                <font-awesome-icon icon="calendar-alt" /> {{new Date(parseInt(message.id))|formatDate}}
                            </small>
                        </div>
                    </div>

                </div>
            </div>
            <div class="bottom-pane row text-center">
                <div class="form-group col-xs-12 col-md-9 col-xl-10">
                    <input :placeholder="$t('support-placeholder')" type="text" class="form-control-lg form-control" v-model="messageContent"/>
                </div>
                <div class="form-group col-xs-12 col-md-3 col-xl-2">
                    <v-btn color="primary" class="float-right text-unset" @click="sendMessage" >{{$t('support-send')}}</v-btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import gql from "graphql-tag";
import {mapGetters} from "vuex";

export default {
name: "SupportPage",
    data() {
        return{
            messages: null,
            messageContent: ''
        }
    },
    computed: {
        ...mapGetters({
            userId: 'user/userId'
        })
    },
    apollo: {
        messages: {
            query: gql`query{messages{id,from,to,message}}`,
            update: data => data
        }
    },
    methods: {
        sendMessage() {
            console.log("Sending message " + this.messageContent)
            this.$apollo.mutate({
                mutation: gql`mutation($message: String!){submitMessage(message:$message)}`,
                variables: {
                    message: this.messageContent
                }
            }).then(() => {
                this.messageContent = ''
                this.$apollo.queries.messages.refetch()
            })
        }
    }
}
</script>

<style scoped>
.chat-box-wrapper-right {
    justify-content: flex-end;
}

.chat-box-right {
    -webkit-box-shadow: 0 0 0 transparent;
    box-shadow: 0 0 0 transparent;
    position: relative;
    opacity: 1;
    background: #d1ffd2;
    border: 0;
    padding: 0.5rem 1rem;
    border-radius: 30px;
    border-bottom-right-radius: 0.25rem;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 50%;
    min-width: 100%;
    text-align: left;
}
</style>